'use client'

import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic'

import './navmat.css';
import React from "react";


import {
  Navbar,
  Collapse,
  Button,
  IconButton,
  Chip,
} from "@material-tailwind/react";
import {
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { useRouter } from 'next/navigation';

const MainNavList = dynamic(() => import('./navlist/MainNavList'), { ssr: false });







export default function MainNav({ children, navSections, navAboutLinks }) {




    const router = useRouter();


  const { t } = useTranslation();

  const [stickyClass, setStickyClass] = useState('relative-nav');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 110 ? setStickyClass('fixed-nav top-0 left-0 z-50') : setStickyClass('relative-nav bg-gray-200');
    }
  };


  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 550 && setOpenNav(false)
    );
  }, []);





  return (
    <Navbar className={`px-0 py-2 bg-transparent w-full max-w-screen-9xl navbar border-t-0 rounded-none  ${stickyClass}`}>
    
      <div className="flex items-center justify-between px-8 text-blue-gray-900">
        <a
          href="/"
          className="mr-1 font-bold text-xl cursor-pointer py-1.5 lg:ml-4 font-['Rubik'] flex items-center"
        >

          <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="20px" viewBox="0 0 99.000000 132.000000" preserveAspectRatio="xMidYMid meet" className='mx-2 '>

            <g transform="translate(0.000000,132.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
              <path fill="#ea252c" d="M443 1229 c4 -35 1 -42 -19 -50 -33 -12 -51 -3 -58 32 -7 35 -22 36 -77 9 l-40 -21 20 -33 c12 -18 21 -36 21 -39 0 -3 -14 -15 -30 -27 l-31 -22 -20 26 c-28 35 -39 33 -73 -16 l-30 -41 28 -22 c25 -20 27 -26 19 -51 -10 -29 -18 -32 -64 -20 -23 7 -27 4 -37 -32 -18 -60 -16 -68 29 -81 37 -11 40 -15 37 -44 -3 -27 -7 -32 -33 -35 -43 -5 -47 -13 -33 -71 l12 -52 36 6 c36 6 38 5 70 -45 l33 -51 28 27 c28 27 28 34 4 64 -22 27 -45 113 -45 166 0 81 34 160 95 219 194 190 511 48 509 -228 0 -38 -6 -73 -12 -79 -7 -7 -12 -19 -12 -29 0 -9 -11 -32 -24 -52 l-24 -35 27 -26 c15 -14 29 -26 32 -26 2 0 15 20 29 45 24 43 26 45 72 45 26 0 49 4 52 9 4 5 9 30 12 55 7 45 6 46 -22 46 -42 0 -56 13 -52 46 2 22 10 30 36 36 41 11 48 28 33 79 -12 39 -12 39 -59 36 -44 -2 -47 0 -50 24 -2 20 4 32 28 49 l30 21 -36 45 -37 45 -27 -26 c-32 -30 -40 -31 -63 -4 -17 19 -17 23 -3 54 9 18 16 36 16 39 0 6 -84 46 -96 46 -2 0 -10 -16 -18 -35 -15 -36 -22 -40 -50 -29 -11 4 -16 19 -16 50 l0 44 -60 0 -61 0 4 -41z" />
              <path d="M416 818 c-3 -7 -7 -56 -10 -108 -10 -144 -17 -187 -35 -207 -9 -10 -43 -52 -75 -93 -71 -89 -174 -209 -198 -230 -10 -8 -15 -20 -10 -26 4 -6 54 -29 112 -52 93 -37 227 -67 310 -70 48 -2 197 28 259 53 69 27 151 72 151 83 0 4 -19 27 -43 52 -40 41 -204 228 -253 289 -19 22 -22 40 -24 126 -1 55 -5 120 -8 145 l-7 45 -83 3 c-60 2 -83 -1 -86 -10z m131 -178 c8 -123 12 -142 31 -163 12 -13 22 -26 22 -30 0 -4 8 -16 18 -26 27 -30 38 -45 65 -87 14 -21 42 -60 61 -86 l35 -47 -67 -22 c-57 -20 -87 -23 -207 -23 -102 0 -152 4 -185 16 -25 9 -58 19 -75 23 -26 6 -27 8 -12 16 9 6 17 16 17 24 0 7 4 15 8 17 4 1 45 59 90 128 l83 124 6 138 6 138 47 0 47 0 10 -140z" />
            </g>
          </svg>
          
          CHIMIART <span className='px-1 mt-1 text-sm text-gray-900/90'>
           
           {t('home')}</span>
           
           

    
        </a>
    

        <div className="hidden navlg:block">
          <MainNavList navSections={navSections} navAboutLinks={navAboutLinks}/>
          {/* {children[0]} */}
        </div>


        <div className='flex gap-2'>

        <div className="flex items-center ">
            
            {children}

        



        </div>







          <div className="hidden gap-2 navlg:flex">
            <a href="/contact/test-sample-request" className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-[10px] px-4 bg-chimib-600 text-white shadow-md shadow-red-900/10 hover:shadow-lg hover:shadow-red-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-full"  
            // onClick={() => handlePopForm(true, "t")}
            >
              {t('rts')}
            </a>
            <a href="/contact/quote-request" className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-[10px] px-4 bg-chimia-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-full"
            // onClick={() => handlePopForm(true, "r")}
            >
              {t('rfq')}
            </a>
          </div>


    
    

          <IconButton
            variant="text"
            color="blue-gray"
            className="navlg:hidden"
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="h-6 w-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="h-6 w-6" strokeWidth={2} />
            )}
          </IconButton>


        
        </div>

      </div>
      <Collapse open={openNav}>
      <MainNavList navSections={navSections} navAboutLinks={navAboutLinks}/>


        <div className=" flex w-full px-4 flex-nowrap items-center justify-between gap-4 navlg:hidden">
          <Button className="rounded-full w-1/2 bg-chimib-600" variant="outlined" size="sm"  fullWidth
          //  onClick={() => handlePopForm(true, "U")}
           >
            {t('rts')}
          </Button>
          <Button className="rounded-full w-1/2" variant="gradient" size="sm" fullWidth 
          // onClick={() => handlePopForm(true, "R")}
          >
            {t('rfq')}
          </Button>
        </div>
      </Collapse>

    </Navbar>
    
  );
}



