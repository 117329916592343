export function CreateContact(){
    const CREATE_CONTACT = `
    mutation($data: create_emails_input!){
    create_emails_item(data: $data) {
        id
        firstName
        lastName
        email
        phone
        address
        company
        industry
        message
  }
}
    `
    return CREATE_CONTACT;
}

export function CreateQuoteRequest(){
    const CREATE_QUOTE=`
    mutation($data: create_quoteRequest_input!){
    create_quoteRequest_item(data: $data) {
        firstName
        lastName
        email
    }
}
    `

    return CREATE_QUOTE;
}

export function CreateSampleRequest(){
    const CREATE_QUOTE=`
    mutation($data: create_sampleRequest_input!){
    create_sampleRequest_item(data: $data) {
        firstName
        lastName
        email
    }
    }
    `

    return CREATE_QUOTE;
}