export async function FormAPI(Query,formData){
    try{
      
        const { data } = await fetch(process.env.GRAPHQL_API_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: Query,
              variables: {data:formData}
            }),
            next: { revalidate: 1 },
          }).then((res) => res.json())
            .then((result) => console.log(result));;
        return data;
    }
    catch(error){
        console.log("error in API: ",error)
    }
  }
  