import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/roboto/300.css");
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/roboto/400.css");
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/roboto/500.css");
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/roboto/700.css");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/CurrencyPoundOutlined.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/FactoryOutlined.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/GroupsOutlined.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/esm/SignalCellularAltOutlined.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/app/public/icons/logo-dark.png");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/globals.css");
import(/* webpackMode: "eager" */ "/app/src/components/home/contactForm/ContactForm.js");
import(/* webpackMode: "eager" */ "/app/src/components/home/header/header.css");
import(/* webpackMode: "eager" */ "/app/src/components/language/LanguageChanger.js");
import(/* webpackMode: "eager" */ "/app/src/components/language/TranslationsProvider.js");
import(/* webpackMode: "eager" */ "/app/src/components/navBar/MainNav.js")